import { AppCallback } from 'next-redux-wrapper';
import type { AppProps } from 'next/app';
import App from 'next/app';
import dynamic from 'next/dynamic';
import 'react-placeholder/lib/reactPlaceholder.css';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import ClientCartPriceWatcher from 'src/components/containers/ClientCartPriceWatcher/ClientCartPriceWatcher';
import MobileAppBanner from 'src/components/containers/MobileAppBanner';
import NoAvailableSlotsModalWatcher from 'src/components/containers/NoAvailableSlotsModalWatcher/NoAvailableSlotsModalWatcher';
import OpenGraphDefault from 'src/components/containers/OpenGraph/OpenGraphDefault';
import RobotsAndCanonicalModeManager from 'src/components/containers/RobotsAndCanonicalModeManager/RobotsAndCanonicalModeManager';
import Loader from 'src/components/views/Loader/Loader';
import DeliveryUrlWatcher from 'src/modules/AddressManagement/components/DeliveryUrlWatcher/DeliveryUrlWatcher';
import { AppEventsHandlers, AppHead, AppHeadProps, AppHorecaWrapper, AppInitComponent, AppMarketing, AppStyles } from 'src/modules/app';
import { getServerOrClientPromotionClosedCookie } from 'src/modules/HeaderPromotionLine/helpers';
import Theme from 'src/modules/Theme/Theme';
import UserAgentProvider from 'src/modules/user-agent/UserAgentProvider';
import { setLanguage, setReduxType } from 'src/redux/app/appActions';
import { initAppOnServerSide } from 'src/redux/app/appOperations/initAppOnServerSide';
import { setInitialAppConfig } from 'src/redux/appConfig/appConfigReducers';
import { NOT_FOUND } from 'src/redux/constants';
import { wrapper } from 'src/redux/initializeStore';
import { RootState } from 'src/redux/reducers';
import { ServicesProvider } from 'src/services';
import { getChainSSR } from 'src/services/chain/getChainSSR';
import { Chain } from 'src/services/chain/types';
import { getServicePagesSSR } from 'src/services/servicePages/getServicePagesSSR';
import { getToggles } from 'src/services/toggles/getToggles';
import { Toggles } from 'src/services/toggles/types';
import 'src/styles/static/icons.css';
import checkIfModified from 'src/utils/api/checkIfModified';
import getInitialAppConfig, { Language } from 'src/utils/appConfig/getInitialAppConfig';
import { selectAppConfig } from 'src/utils/appConfig/selectAppConfig';
import { getUserPreferencesFromCookies } from 'src/utils/pages/_app/_appUtils';
import { getIsServer } from 'src/utils/system/getIsServer';
import getRedirectPath from 'src/utils/system/redirectPath/getRedirectPathCached';
import { SWRConfig } from 'swr';
import HeaderPromotionProvider from '../modules/HeaderPromotionLine/HeaderPromotionProvider';
import requestGetMainPromotion from '../modules/HeaderPromotionLine/requestGetMainPromotion';
import { InteractWatcherProvider } from '../modules/interact-watcher';
import RecommendationsModalWatcher from '../modules/RecommendationsModal/components/RecommendationsModalWatcher';
import { Offer, ServicePage } from '../redux/apiTypes';

const ChainIsNotActiveModal = dynamic(
  () => import('src/components/containers/ChainIsNotActiveModal/ChainIsNotActiveModal'),
  { ssr: false },
);

const NoSlotsModal = dynamic(
  () => import('src/components/containers/NoSlotsModal/NoSlotsModal'),
  { ssr: false },
);

const ErrorPage = dynamic(
  () => import('./_error'),
  {
    ssr: true,
    loading: Loader,
  },
);

/**
 * @description Implementation with named export made for unit tests without redux wrapper.
 * @todo Fix types
 */
//@ts-ignore
export const getInitialPropsWrapped: AppCallback<Store<RootState>, InitialProps> = (redux) => async (appContext) => {
  const { ctx, router } = appContext;
  const { req, res, query, pathname, asPath } = ctx;
  const isServer = getIsServer();
  const userAgent = req?.headers['user-agent'];

  /**
   * @todo Use native Next.js errors mechanic
   */
  let isNotFound = false;

  /**
   * @todo Move clientType definition out from _app.getInitialProps
   */
  let clientType;
  let headerPromotion = null;
  let toggles: Toggles = null;
  let chainData: Chain = null;
  let servicePages: ServicePage[] = null;

  if (isServer) {
    redux.dispatch(setReduxType('server'));

    const initialAppConfig = await getInitialAppConfig(query, req, res);

    if (!initialAppConfig) {
      return {};
    }

    const userPreferences = getUserPreferencesFromCookies(req);
    clientType = userPreferences.clientType;

    /**
     * query.lang is the primary source of truth for the language
     * after all redirects are processed.
     */
    const appLang = query.lang as Language;

    redux.dispatch(setLanguage(appLang));
    redux.dispatch(setInitialAppConfig(initialAppConfig));

    const appConfig = selectAppConfig(redux.getState());

    const { host, fetchConfig } = appConfig;

    toggles = await getToggles(fetchConfig);
    chainData = await getChainSSR(fetchConfig);
    servicePages = await getServicePagesSSR(fetchConfig, host);

    /**
     * @todo Move all redirects logic into Next.js middleware
     */
    const { newPath, redirectType, isNoLangRote } = await getRedirectPath(
      {
        preferredLanguage: userPreferences.language,
        storeId: userPreferences.storeId,
        path: asPath,
        clientType,
      },
      toggles,
      appConfig,
    );

    if (newPath !== asPath) {
      if (newPath === NOT_FOUND) {
        res.statusCode = 404;
        isNotFound = true;
      } else {
        res.writeHead(redirectType, { Location: newPath });
        res.end();
        return {};
      }
    }

    /**
     * This prevents a race condition with noLangRoute-pages,
     * where res.setHeader() calls are made, causing to server errors.
     */
    if (isNoLangRote) {
      return {};
    } else {
      const hasModification = checkIfModified(res, req);

      if (!hasModification && !userPreferences.deliveryType) {
        res.statusCode = 304;
        res.end();
        return {};
      }
    }

    const baseURL = `https://${host}/${query.lang}`;

    await redux.dispatch(initAppOnServerSide({
      host,
      baseURL,
      storeId: userPreferences.storeId,
      deliveryType: userPreferences.deliveryType,
    }));
  }

  if (!isServer) {
    /**
     * @description Handle SPA `/` route by redirecting to '/[lang]'
     */
    if (pathname === '/') {
      const lang = redux.getState().app.language;
      router.push('/[lang]', `/${lang}`);
      return {};
    }
  }

  /**
   * fetching header promotion
   * not only for ssr
   * because the data was nulled during client routing
   */
  if (!getServerOrClientPromotionClosedCookie(req)) {
    const updatedState = redux.getState();
    const { fetchConfig } = selectAppConfig(updatedState);
    const {
      id: storeId,
      delivery_service: deliveryService,
    } = updatedState.storeManagement.store;

    headerPromotion = await requestGetMainPromotion(
      fetchConfig,
      deliveryService,
      storeId,
    );
  }

  const initialProps = {
    pageProps: {
      // https://nextjs.org/docs/advanced-features/custom-app#caveats
      ...(await App.getInitialProps(appContext)).pageProps,
    },

    toggles,
    chainData,
    servicePages,
    isNotFound,
    clientType,
    userAgent,
    headerPromotion,
  } as InitialProps;

  return initialProps;
};

MyApp.getInitialProps = wrapper.getInitialAppProps(getInitialPropsWrapped);

export interface InitialProps extends AppProps {
  isNotFound: boolean;
  clientType: string;
  userAgent: string;
  headerPromotion: Offer | null;
  toggles: Toggles;
  chainData: Chain;
  servicePages: ServicePage[];
}

export default function MyApp({ Component, ...restProps }) {
  const { store: redux, props } = wrapper.useWrappedStore(restProps);

  const {
    pageProps,
    isNotFound,
    clientType,
    userAgent,
    router,
    headerPromotion,
    toggles,
    chainData,
    servicePages,
  }: InitialProps = props;

  /**
   * @description This state won't evaluate, and doesn't trigger rerenders.
   * Use App sub components instead.
   */
  const state = redux.getState();

  const { host, languages, country, skin, siteTitle } = selectAppConfig(state);

  const translations = state.translations.data;

  const { query, asPath } = router;

  const appHeadProps: AppHeadProps = {
    asPath,
    host,
    languages,
    country,
    siteTitle,
    currentLang: query.lang as string,
  };

  return (
    <Provider store={redux}>
      <UserAgentProvider userAgent={userAgent} >
        <InteractWatcherProvider>
          <ServicesProvider
            translations={translations}
            toggles={toggles}
            chainData={chainData}
            initialServicePages={servicePages}
          >
            <Theme skin={skin} />
            <AppInitComponent />
            <AppMarketing />
            <AppHead {...appHeadProps}/>

            <SWRConfig value={{ dedupingInterval: 600000, errorRetryCount: 2 }} >
              <AppHorecaWrapper clientType={clientType} >
                <RobotsAndCanonicalModeManager />
                <OpenGraphDefault />{/* must be placed before <Component... line */}
                <AppStyles />
                <HeaderPromotionProvider headerPromotion={headerPromotion}>
                  {isNotFound && <ErrorPage statusCode={404} />}
                  {!isNotFound && <Component {...pageProps} />}
                </HeaderPromotionProvider>
                {<ChainIsNotActiveModal />}
                <MobileAppBanner />
                <NoAvailableSlotsModalWatcher />
                {query.delivery && <DeliveryUrlWatcher />}
                <ClientCartPriceWatcher />
                <RecommendationsModalWatcher />
                <AppEventsHandlers />
                <NoSlotsModal />
              </AppHorecaWrapper>
            </SWRConfig>
          </ServicesProvider>
        </InteractWatcherProvider>
      </UserAgentProvider>
    </Provider>
  );
}

/* eslint no-restricted-imports: [
  "error",
  {
    "paths": [
      {
        "name": "react",
        "importNames": ["useEffect"],
        "message": "Please note, if you want to use React.useEffect like componentDidMount in _app component, you will get race condition with children components. You can use <AppInitComponent> or <AppMarketing> instead."
      }
    ]
  }
],*/
