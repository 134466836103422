import { Dispatch } from 'redux';
import { DeliveryType } from 'src/redux/apiTypes';
import setSelectedAddress from '../../addressManagement/setSelectedAddress';
import requestGetStoreById from '../../storeManagement/requestGetStoreById';
import { getTranslations } from '../../translations/translationsActions';
import { setBaseURL } from '../appActions';

interface Params {
  storeId: string;
  baseURL: string;
  deliveryType?: DeliveryType;
  host?: string;
}

export const initAppOnServerSide = (params: Params) => async (
  dispatch: Dispatch,
) => {
  const {
    storeId,
    baseURL,
    deliveryType = null,
    host,
  } = params;

  const res = await dispatch(requestGetStoreById(storeId));

  if ('meta' in res && res.meta?.status === 404) {
    await dispatch(requestGetStoreById('default'));
  }

  dispatch(setSelectedAddress({
    type: deliveryType,
    address: {},
    hash: '',
  }));
  dispatch(setBaseURL(baseURL, host));

  await dispatch(getTranslations());
};
