import { createContext, ReactNode, useContext } from 'react';
import { ServicePage } from 'src/redux/apiTypes';
import { useAppSelector } from 'src/redux/hooks';
import { Toggles } from 'src/services/toggles/types';
import { useAppConfig } from 'src/utils/appConfig/useAppConfig';
import ChainContext from './chain/chainContext';
import { Chain } from './chain/types';
import useChainLogic from './chain/useChainLogic';
import DeliveryPresets from './deliveryPresets/deliveryPresetsContext';
import useDeliveryPresetsService from './deliveryPresets/useDeliveryPresetsService';
import useFavoritesService, { FavoritesService } from './favorites/useFavoritesService';
import { LocalizeFunc, TranslationDictionary } from './localization/types';
import { useLocalizationService } from './localization/useLocalizationService';
import { NotificationService } from './notification/types';
import useNotificationService from './notification/useNotificationService';
import ServicePagesContext from './servicePages/servicePagesContext';
import useServicePagesLogic from './servicePages/useServicePagesLogic';
import TogglesContext from './toggles/togglesContext';
import useTogglesService from './toggles/useTogglesService';

export interface Services {
  favorites: FavoritesService;
  localization: LocalizeFunc;
  notification: NotificationService;
}

interface ServicesProviderProps {
  toggles: Toggles;
  children: ReactNode;
  translations: TranslationDictionary;
  chainData: Chain;
  initialServicePages: ServicePage[];
}

export const ServicesContext = createContext<Services>(null);

export const useServices = () => useContext(ServicesContext);

export const ServicesProvider = ({
  toggles,
  children,
  chainData,
  translations,
  initialServicePages,
}: ServicesProviderProps) => {
  const { host } = useAppConfig();
  const isLoggedIn = useAppSelector((s) => s.user.isLoggedIn);
  const notification = useNotificationService();
  const favorites = useFavoritesService(isLoggedIn, notification.openFavoriteNotification);
  const localization = useLocalizationService(translations);
  const deliveryPresets = useDeliveryPresetsService(isLoggedIn);
  const togglesState = useTogglesService(toggles);
  const chainState = useChainLogic(chainData);
  const servicePages = useServicePagesLogic({
    trueHost: host,
    chainHost: chainState.hostname,
    initialData: initialServicePages,
  });

  const contextValue = {
    favorites,
    localization,
    notification,
  };

  return (
    <ServicesContext.Provider value={contextValue}>
      <DeliveryPresets.Provider value={deliveryPresets}>
        <TogglesContext.Provider value={togglesState}>
          <ChainContext.Provider value={chainState}>
            <ServicePagesContext.Provider value={servicePages}>
              {children}
            </ServicePagesContext.Provider>
          </ChainContext.Provider>
        </TogglesContext.Provider>
      </DeliveryPresets.Provider>
    </ServicesContext.Provider>
  );
};
